import './App.scss';
import React from 'react';
import { Suspense, lazy} from 'react';
import TwentyFiveApp from './twenty-five/TwentyFiveApp';
//import CalculatorApp from './calculator/CalculatorApp.jsx';
//import TreeMapApp from './treemap/TreemapApp.jsx';
//import ExerciseTrackerApp from './exercise-tracker/ExerciseTrackerApp.jsx'
//import NumberGuessApp from './number-guesser/NumberGuessApp.jsx';
//import MarkdownPreviewer from './mkdn-previewer/MarkdownPreviewer.jsx';
//import QuoteMachine from './quote-machine/QuoteMachine.jsx';
//import DrumApp from './drumapp/DrumApp.jsx';
//import BarChart from './barchart/BarChart.jsx';
//import ScatterPlot from './scatter/ScatterPlot.jsx';
//import HeatMap from './heatmap/HeatMap.jsx';
//import ChoroMap from './choropleth/ChoroplethMap.jsx';
//import EarlyWork from './earlywork/EarlyWork.jsx';
import LoadingComp from './loading/LoadingComp.jsx';
import { projectTileData } from './functions/projTileData.js'
//import NodeResponse from './node-response/NodeResponse.jsx'
//import * as d3 from 'd3';
import { goComp  } from './App-actions.js';
import { connect } from 'react-redux';
//import store from './store.js';
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
//import { scroller } from 'react-scroll';
import { faGithub, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope, /*faPhone*/ } from '@fortawesome/free-solid-svg-icons';
//import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

//twentyFiveApp breaks homepage styling when lazy imported
//const TwentyFiveApp = lazy(() => import('./twenty-five/TwentyFiveApp'));
const CalculatorApp = lazy(() => import('./calculator/CalculatorApp.jsx'));
const TreeMapApp = lazy(() => import('./treemap/TreemapApp.jsx'));
const ExerciseTrackerApp = lazy(() => import('./exercise-tracker/ExerciseTrackerApp.jsx'));
const NumberGuessApp = lazy(() => import('./number-guesser/NumberGuessApp.jsx'));
const MarkdownPreviewer = lazy(() => import('./mkdn-previewer/MarkdownPreviewer.jsx'));
const QuoteMachine = lazy(() => import('./quote-machine/QuoteMachine.jsx'));
const DrumApp = lazy(() => import('./drumapp/DrumApp.jsx'));
const BarChart = lazy(() => import('./barchart/BarChart.jsx'));
const ScatterPlot = lazy(() => import('./scatter/ScatterPlot.jsx'));
const HeatMap = lazy(() => import('./heatmap/HeatMap.jsx'));
const ChoroMap = lazy(() => import('./choropleth/ChoroplethMap.jsx'));
const EarlyWork = lazy(() => import('./earlywork/EarlyWork.jsx')) ;

class HomePageApp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      //showAll: false
    };
    //this.handleShowAll = this.handleShowAll.bind(this);
    this.projTileClick = this.projTileClick.bind(this);
  }

  /*
  handleShowAll = () => {
    if (this.state.showAll === false) {
      //console.log('at top')
      //console.log(this.props.rootReducer.displayComponent)
      this.setState(state => ({
        showAll: true
      }));
    } else {
      this.setState(state => ({
        showAll: false
      }))
    }
  }
  */
/*
  componentDidUpdate() {
    //if(window.location.pathname === "/" && this.state.currentPage === "not /"){
      this.setState(state => ({
        currentPage: "/"
      }));
      
      console.log("/ is current page")
    } else if(window.location.pathname === "not /" && this.state.currentPage === "/") {
      this.setState(state => ({
        currentPage: "not /"
      }))
      console.log("not / is current page")
    }
      
  }*/

  componentDidMount() {
    //console.log("Path: " + window.location.pathname + ' , Hash: ' + window.location.hash)
    //console.log(window.location.hash)
    /**/
    this.setState(state => ({
      currentPage: "/"
    }));
    if(window.location.hash !== "") {
      this.handleHomeHash();
    }
  }

  handleHomeHash = () => {
    switch (window.location.hash) {
      case "#about-scroll":
        //console.log('confirmed # of: ' + window.location.hash)
        document.getElementById('about-scroll').scrollIntoView({ behavior: 'smooth' });
        break;
      case "#project-scroll":
        //console.log('confirmed # of: ' + window.location.hash)
        document.getElementById('project-scroll').scrollIntoView({ behavior: 'smooth' });
        break;
      case "#contact":
        //console.log('confirmed # of: ' + window.location.hash)
        document.getElementById('contact').scrollIntoView({ behavior: 'smooth' });
        break;
      default:
        document.getElementById('welcome-section').scrollIntoView({ behavior: 'smooth' });
    }
  }

  projTileClick = (/*projComponent*/) => {
    //this.props.onNewComp('twentyFive')
  }

  render() {
    let tileData = projectTileData(this.props.showAll)
    const profilePic = "../images/profile_picture_brcrop.jpg"
    //const testingPicture = "../images/twentyFiveImg.png"

    return (
      <div className="home-page-app">
        
        <main id="main-section" >
          <div id="welcome-section">
            <div id="left-welcome">
              <h1 className="home-name" >NICHOLAS GUITAR</h1>
              <h2 className="roboto-regular" >Software Developer in Clawson, MI</h2>
              <div id="welcome-buttons" >
                <a className="welcome-button" href="#contact" id="work-tog-butt" >Reach Out</a>
                <a className="welcome-button" href="#project-scroll" >My Projects</a>
              </div>
            </div>
            <div id="welcome-img">
              <img src={profilePic} alt="Nick Pic"  />
            </div>
            
          </div>
          <div id="about-scroll" ></div>
          <div id="about-section">
            <div id="about-para">
              <h1>About</h1>
              <p>My name is Nicholas Guitar, and I'm a software developer located in 
              Clawson, MI.  I love sports, history, and a good Fantasy or Sci-Fi book.  I spend lots of
              time with friends and family doing all sorts of things, including playing golf,  
              competing at board games, or simply sharing a meal together.</p>
              <p>I studied Mechanical Engineering at Ohio Northern University until 2017, and 
              began my career as an automotive Design Engineer and Program Manager.  Between school and work, I 
              discovered a passion for programming! Whether it was a robotics class in college, or 
              automating aspects of my program management with Excel VBA, I found myself captivated 
              by the creative and rewarding nature of building software. I decided in 2023 to begin 
              turning my programming hobby into a career. </p>
              <p>This website, along with the various apps integrated into it, demonstrate the skills 
              I've built along the way.  This site is built with React and Node.js, and includes apps 
              built with other front end Javascript frameworks like Redux and D3.js. 
              You will also find apps utilizing either PostgreSQL or Mongoose databases.</p>
              <p id="project-scroll" >If you're looking for someone with successful product launch experience,
              an ability to build relationships inside and outside of your organization, or just a strong
              passion for software development, let's work together!</p>
              
            </div>
            
          </div>

          <div id="projects" ref={this.projectsRef} >
            <h1 id="projects-header" > Projects </h1>
            <section className="project-tiles">
            {/*showAll state, make map function for tiles below*/}
            {tileData.map((item, index) => (
              //let newCompName = tileData[index][4];
              <Link key={index} to={tileData[index][4]} id={tileData[index][4]} 
              className="project-tile" onClick={this.props.onNewComp} >           
                {/*onClick={this.projTileClick(tileData[index][4])}*/}
                <div className='tile-description' >

                  <h2 className="description-title" >
                    <span className="hiding-text">&lt;&nbsp;</span>
                    <span>{tileData[index][0]}</span>
                    <span className="hiding-text">&nbsp;/&gt;</span>
                  </h2>
                  <p>{tileData[index][1]}</p>
                  
                </div>

                <div className='tile-img-and-title' >
                  <img src={tileData[index][3]} alt={tileData[index][2]} />
                  <h2 className="img-title" >
                    <span className="hiding-text">&lt;&nbsp;</span>
                    <span>{tileData[index][2]}</span>
                    <span className="hiding-text">&nbsp;/&gt;</span>
                  </h2>
                </div>

              </Link>
            ))}

            </section>

            {this.props.showAll === true
              ? <h2 id="show-all" onClick={this.props.handleShowAll} >Show Less</h2>
              : <h2 id="show-all" onClick={this.props.handleShowAll} >Show All</h2>
            }
            
          </div>
          
          <section id="contact">
            <h1>Let's work together!</h1>
            <ul id="contact-links">
              <a href="https://github.com/nmguitar/Portfolio-NicholasGuitar">
                <li id="github">
                  <FontAwesomeIcon icon={faGithub} />
                  <span className="contact-text">&nbsp;&nbsp;GitHub</span>
                </li>
              </a>
              <a href="https://www.linkedin.com/in/nicholas-guitar-043777a3/" >
                <li id="linkedin" >
                  <FontAwesomeIcon icon={faLinkedin} />
                  <span className="contact-text">&nbsp;&nbsp;LinkedIn</span>
                </li>
              </a>  
              <a href="mailto:nmguitar@outlook.com">
                <li id="email">
                  <FontAwesomeIcon icon={faEnvelope} />
                  <span className="contact-text">&nbsp;&nbsp;nmguitar@outlook.com</span>
                </li>
              </a>
              
                
  
            </ul>
          </section>
        </main>
  
      </div>
    );
  }
}

class comboApp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      scrollYPos: "top",
      isHome: true,
      showAll: false,
      d3Width: 800,
      d3Height: 400
    };
    this.handleHomeClick = this.handleHomeClick.bind(this);
    this.handleShowAll = this.handleShowAll.bind(this);
  }

  componentDidMount() {
    //console.log("render state: ")
    //console.log(store.getState())
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll = () => {
    if (window.scrollY === 0) {
      //console.log('at top')
      //console.log(this.props.rootReducer.displayComponent)
      this.setState(state => ({
        scrollYPos: "top"
      }));
    } else {
      this.setState(state => ({
        scrollYPos: "notTop"
      }))
    }
  }

  handleHomeClick = () => {
    if(window.location.pathname === '/') {
      window.scrollTo(0,0);
    }
    this.setState(state => ({
      isHome: true
    }))
  }

  handleNavClick = () => {
    this.setState(state => ({
      isHome: true
    }))
  }

  handleProjClick = () => {
    this.setState(state => ({
      isHome: false
    }))
    setTimeout(() => {
      window.scrollTo(0,0);
    }, 100);
  }

  handleShowAll = () => {
    if (this.state.showAll === false) {
      //console.log('at top')
      //console.log(this.props.rootReducer.displayComponent)
      this.setState(state => ({
        showAll: true
      }));
    } else {
      this.setState(state => ({
        showAll: false
      }))
    }
  }

  render() {
    //console.log("render state: ")
    //console.log(store.getState())
    //console.log(window.location.pathname + ' and ' + window.location.hash)
    //console.log('currentPage: ' + this.state.currentPage)
    //console.log('isHome: ')
    //console.log(this.state.isHome)
    const navNotTopColor = "rgba(13, 5, 0, 0.5)";
    const navTopColor = "rgba(13, 5, 0, 0)";
    const navBorderStyle = "1px solid hsl(24,100%, 15%)";
    const logoDim = "50px";


    /*
    <Route path='twentyFive' element={
            <Suspense fallback={ <LoadingComp /> }>
              <TwentyFiveApp/>
            </Suspense>
          }/>
          <Route path='calculator' element={
            <Suspense fallback={ <LoadingComp /> }>
              <CalculatorApp/>
            </Suspense>
          }/>
          <Route path='treemap' element={
            <Suspense fallback={ <LoadingComp /> }>
              <TreeMapApp d3Width={this.state.d3Width} d3Height={this.state.d3Height} />
            </Suspense>
          } />
          <Route path='exercise' element={
            <Suspense fallback={ <LoadingComp /> }>
              <ExerciseTrackerApp />
            </Suspense>
          }/>
          <Route path='exercise/api/users' element={
            <Suspense fallback={ <LoadingComp /> }>
              <ExerciseTrackerApp />
            </Suspense>
          }/>
          <Route path='exercise/api/users/:username/logs' element={
            <Suspense fallback={ <LoadingComp /> }>
              <ExerciseTrackerApp /> 
            </Suspense>
          }/>
          <Route path='numguess' element={
            <Suspense fallback={ <LoadingComp /> }>
              <NumberGuessApp />
            </Suspense>
          }/>

          <Route path='twentyFive' element={ <TwentyFiveApp/> }/>
          <Route path='calculator' element={ <CalculatorApp/> }/>
          <Route path='treemap' element={ <TreeMapApp d3Width={this.state.d3Width} d3Height={this.state.d3Height} /> } />
          <Route path='exercise' element={ <ExerciseTrackerApp /> }/>
          <Route path='exercise/api/users' element={ <ExerciseTrackerApp /> }/>
          <Route path='exercise/api/users/:username/logs' element={ <ExerciseTrackerApp /> }/>
          <Route path='numguess' element={ <NumberGuessApp /> }/>
     */

    return(
      

      <Router  >
        <Routes>
          <Route path='/' element={ 
            <HomePageApp 
              onNewComp={this.handleProjClick} 
              showAll={this.state.showAll}
              handleShowAll={this.handleShowAll}
            /> 
          }/>
          <Route path='twentyFive' element={ <TwentyFiveApp/> }/>
          <Route path='calculator' element={
            <Suspense fallback={ <LoadingComp /> }>
              <CalculatorApp/>
            </Suspense>
          }/>
          <Route path='treemap' element={
            <Suspense fallback={ <LoadingComp /> }>
              <TreeMapApp d3Width={this.state.d3Width} d3Height={this.state.d3Height} />
            </Suspense>
          } />
          <Route path='exercise' element={
            <Suspense fallback={ <LoadingComp /> }>
              <ExerciseTrackerApp />
            </Suspense>
          }/>
          <Route path='exercise/api/users' element={
            <Suspense fallback={ <LoadingComp /> }>
              <ExerciseTrackerApp />
            </Suspense>
          }/>
          <Route path='exercise/api/users/:username/logs' element={
            <Suspense fallback={ <LoadingComp /> }>
              <ExerciseTrackerApp /> 
            </Suspense>
          }/>
          <Route path='numguess' element={
            <Suspense fallback={ <LoadingComp /> }>
              <NumberGuessApp />
            </Suspense>
          }/>
          <Route path='markdown' element={
            <Suspense fallback={ <LoadingComp /> }>
              <MarkdownPreviewer /> 
            </Suspense>
          } />   
          <Route path='quotegenerator' element={
            <Suspense fallback={ <LoadingComp /> }>
              <QuoteMachine /> 
            </Suspense>
          } />
          <Route path='drum' element={
            <Suspense fallback={ <LoadingComp /> }>
              <DrumApp />
            </Suspense>
          } />
          <Route path='bargraph' element={
            <Suspense fallback={ <LoadingComp /> }>
              <BarChart d3Width={this.state.d3Width} d3Height={this.state.d3Height} />
            </Suspense>
          } />
          <Route path='scatterplot' element={
            <Suspense fallback={ <LoadingComp /> }>
              <ScatterPlot d3Width={this.state.d3Width} d3Height={this.state.d3Height} />
            </Suspense>
          } />
          <Route path='heatmap' element={
            <Suspense fallback={ <LoadingComp /> }>
              <HeatMap d3Width={this.state.d3Width} d3Height={this.state.d3Height} />
            </Suspense>
          } />
          <Route path='choropleth' element={
            <Suspense fallback={ <LoadingComp /> }>
              <ChoroMap d3Width={this.state.d3Width} d3Height={this.state.d3Height} />
            </Suspense>
          } />
          <Route path='earlywork' element={
            <Suspense fallback={ <LoadingComp /> }>
              <EarlyWork /> 
            </Suspense>            
          } />
          <Route path='loading' element={ <LoadingComp /> } />
        </Routes>
        
        <div id="appContainer" >
          <header>
          
            <div id="navbar" 
            style={this.state.scrollYPos === "top" && window.location.pathname === "/"
                    ? {backgroundColor: navTopColor, transition: "0.2s",}
                    : {backgroundColor: navNotTopColor, transition: "0.2s", border: navBorderStyle}
                  }
            >
              <ul id="left-ul" >
                <li onClick={this.handleNavClick}>
                  {window.location.pathname === "/" 
                    ? <a id="nav-about" href="#about-scroll">About</a>
                    : <Link to="/#about-scroll" id="nav-about">About</Link>  
                  }
                </li> 
                <li onClick={this.handleNavClick}>
                  {window.location.pathname === "/"
                    ? <a href="#project-scroll" >Apps</a>
                    : <Link to="/#project-scroll" >Apps</Link>
                  }
                  
                </li>
              </ul>
              <Link to='/' onClick={this.handleHomeClick}>
                <img src={"../guitar-icon-png-white.png"} alt="Guitar Logo" height={logoDim} width={logoDim} />        
              </Link>
              {window.location.pathname === "/" 
                ? <a href="#contact" id="contact-a" onClick={this.handleNavClick}>Contact</a>
                : <Link to="/#contact" id="contact-a" onClick={this.handleNavClick}>Contact</Link>
              }
              
            </div>
          </header>

        </div>
      </Router>
      
    )
  }
}

const mapStateToProps = (state) => {
  return {
    rootReducer: {
      displayComponent: state.displayComponent
    }
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    changeComp: (component) => {
      dispatch(goComp(component));
    }
  }
}

const App = connect(mapStateToProps, mapDispatchToProps)(comboApp);

export default App;
