import React from 'react';
//import { /*useState*/ } from 'react';

export default function LoadingComp(){
  
  return(
    <div id="loadingContainer">
      <img src={"../guitar-icon-png-white.png"} alt="Guitar Logo" />
      <h1>Loading
        <span id="load-dot-one" className="load-elipses" >.</span>
        <span id="load-dot-two" className="load-elipses">.</span>
        <span id="load-dot-three" className="load-elipses">.</span>
      </h1>
    </div>
  )
  
}